.App {
  margin-top: 1%;
  text-align: start;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
}

.App-2 {
  margin-top: 1%;
  margin-left: 1%;
  margin-right: 1%;
  text-align: start;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
  background-color: #61dafb;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 500vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(14, 13, 13);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.icon_pulse {
  animation: circle 1.2s steps(8) infinite;
}

@keyframes circle {
  from {
    transform: rotate(90deg);
  }

  to {
    transform: rotate(450deg);
  }
}

.spinner {
  animation: spin infinite 5s linear;

  /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.custom-tab-pane {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.form-check-input:checked {
  background-color: #171821;
  border-color: #171821;
}

.form-check-input:focus {
  box-shadow: 0 0 0 .25rem white;
}