.spinner {
    width: 100%;
    height: 100%;
  }
  
  .spinner > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    margin-bottom: 2%;
  } 